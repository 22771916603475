import { Socket } from 'ngx-socket-io';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { User } from '@api/models/user.entity';
import { Store } from '@ngrx/store';

import { GamesActions } from '../games/games.actions';
import { GamesFacade } from '../games/games.facade';
import { UsersFacade } from '../users/users-facade';
import { UsersActions } from '../users/users.actions';
import { AppActions } from './app.actions';

@Injectable()
export class AppFacade {
	public get beVersion$(): Observable<string> {
		return this._beVersion.asObservable();
	}

	private _beVersion: BehaviorSubject<string> = new BehaviorSubject(null);

	constructor(
		private store: Store,
		private socket: Socket,
		private usersFacade: UsersFacade,
		private gamesFacade: GamesFacade,
	) {
		combineLatest([
			this.usersFacade.current$,
			this.gamesFacade.id$,
			this.gamesFacade.over$,
		]).subscribe(([user, gameId, gameIsOver]: [User, string, boolean]) => {
			this.socket.on('disconnect', () => {
				if (user && !user.isAnonymous)
					this.store.dispatch(UsersActions.join());

				if (gameId && !gameIsOver)
					this.store.dispatch(GamesActions.join({ gameId }));
			});
		});
	}

	public initialize(): void {
		this.store.dispatch(AppActions.initialize());
	}

	public setBeVersion(version: string): void {
		this._beVersion.next(version);
	}
}
